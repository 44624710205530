<template>
  <div class="h-12 w-full max-w-[640px] transition-all"
       :class="{'text-sm':is_mobile}"
  >
    <div class="w-full relative text-dark-primary main-input-box">
      <div
          @click="clearYear"
          class="absolute left-0 cursor-pointer top-0 pl-10 bg-white !overflow-hidden transition-all  rounded-l-full h-12 z-50 duration-200 flex items-center justify-center pt-4"
          :class="{'bg-secondary shadow-custom-right': selected_year}"
          :style="year_box_styles">
        <div class="absolute top-[4px] w-full text-center text-xs font-bold text-primary" v-if="selected_year">
          Year
        </div>
        {{ this.selected_year.name }}
      </div>
      <div
          @click="clearMake"
          class="absolute  top-0  cursor-pointer overflow-hidden bg-secondary text-sm  transition-all h-12 z-40 duration-300 delay-100 flex items-center justify-center pt-4"
          :class="{'shadow-custom-right': selected_make}"
          :style="make_box_styles">
        <div class="absolute top-[4px] w-full  text-center text-xs font-bold text-primary" v-if="selected_make">
          Make
        </div>
        {{ formatText(this.selected_make.name) }}
      </div>
      <div
          @click="clearModel"
          class="absolute  top-0   cursor-pointer bg-secondary overflow-hidden transition-all h-12 z-30 flex duration-500 items-center justify-center pt-4"
          :style="model_box_styles"
          :class="{'rounded-r-full':is_mobile}"
      >
        <div class="absolute top-[4px] w-full text-center text-xs font-bold text-primary "
             v-if="selected_model">
          Model
        </div>
        {{ formatText(this.selected_model.name) }}
      </div>
      <div
          class="absolute w-12 h-12 flex items-center justify-center overflow-hidden bg-white rounded-full  transition-all left-0 top-0 z-[100] duration-700"
          :class="{'!w-full':modal_spawned}"
      >
        <img :src="this.selected_make.logo" v-if="this.selected_make && this.selected_make.logo"
             class="w-10 h-10 "
             alt="">
        <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_8NYY2Y.json"
                       background="transparent" speed="1" class="w-10 h-10 transition-all"
                       :class="{'!w-14 !h-14 -mt-1':modal_spawned}"
                       loop v-else
                       autoplay></lottie-player>
      </div>

      <input type="text"
             ref="main_input"
             class="w-full h-12 main-input pt-4 z-10 absolute top-0 left-0 transition-all rounded-full outline-none shadow-md"
             :style="input_styles"
             :class="{'!bg-secondary': this.selected_model, 'w-[440px]': selected_model && !is_mobile}"
             v-model="query"
             @focus="onInputFocus"
             @blur="onInputBlur"
             @keydown.do.down="updateSelectedKey(+1)"
             @keydown.do.up="updateSelectedKey(-1)"
             @keydown.delete="deleteButtonPressed"
             @keydown.enter="selectKey"
      >
      <div
          class="absolute  block opacity-0 left-0 w-[34px] h-[34px] top-[7px] shadow-custom-right bg-secondary z-[5] rotate-45 transition-all duration-700"
          :class="{' opacity-100  !left-[423px] shadow-md': selected_model,  'hidden':is_mobile}"
      >
      </div>
      <div
          @click="spawnModal"
          class="absolute  flex pointer-events-none cursor-pointer shadow-md items-center opacity-0 justify-center h-12 w-[200px] z-0  duration-700 transition-all bg-primary text-white left-0 top-0 rounded-r-full"
          :class="{' opacity-100 !pointer-events-auto !left-[440px] ': selected_model, 'hidden': is_mobile}"
      > Get a Free Quote
      </div>
      <div class="absolute pointer-events-none z-20 transition-all top-[12px] left-0 text-gray-500"
           :style="placeholder_styles"
      >{{ current_placeholder }}
      </div>
      <div
          v-if="input_focused"
          ref="optionsDropdown"
          class="options max-w-[400px] overflow-scroll no-scrollbar absolute top-16 left-4 max-h-[200px]   rounded-lg bg-white shadow-md"
          :style="dropdown_box_styles"
      >
        <div v-for="(row,key) in filteredData"
             class="py-2 cursor-pointer hover:bg-secondary px-4 bg-white border-b border-gray-50"
             :class="{'bg-primary text-white hover:bg-primary ':key==selected_key}"
             @click="selectKey(key)"
        >
          {{ row.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpClient from "@/client";
import VehicleDataService from "@/services/VehicleDataService";

export default {
  data() {
    return {
      dimensions: {
        year_width: 0,
        make_width: 0,
        model_width: 0,
      },
      query: "",
      show_main_cta: true,
      selected_year: false,
      selected_make: false,
      selected_model: false,
      input_focused: false,
      selected_key: 0,
      car_years: [],
      car_models: [],
      car_makes: [],
      current_placeholder: "Enter year/make/model",
      modal_spawned: false,
      is_mobile: false
    }
  },
  computed: {
    input_styles() {
      let padding_left = 60;

      if (this.selected_year) {
        padding_left = this.dimensions.year_width + 20;
      }

      if (this.selected_make) {
        padding_left += this.dimensions.make_width;
      }

      return `padding-left: ${padding_left}px`;
    },
    placeholder_styles() {
      let font_size = 16;
      if (this.input_focused) {
        font_size = 12;
      }
      return `${this.input_styles};font-size:${font_size}px;top: ${this.input_focused ? 4 : 12}px;`;
    },
    year_box_styles() {
      return `width: ${this.selected_year ? this.dimensions.year_width : 30}px;`
    },
    make_box_styles() {
      let font_size = this.is_mobile ? 14 : 16;
      if (this.selected_make && this.selected_make.name.length * 10 + 20 > this.dimensions.make_width) {
        font_size = 14;
      }
      return `font-size: ${font_size}px;width: ${this.selected_make ? this.dimensions.make_width : 0}px;left: ${this.selected_make ? this.dimensions.year_width : 30}px;`
    },
    model_box_styles() {
      let font_size = this.is_mobile ? 14 : 16;
      if (this.selected_model && this.selected_model.name.length * 10 + 20 > this.dimensions.model_width) {
        font_size = 14;
      }
      return `font-size: ${font_size}px;width: ${this.selected_model ? this.dimensions.model_width : 0}px;left: ${this.selected_model ? this.dimensions.year_width + this.dimensions.make_width : 30}px;`
    },
    dropdown_box_styles() {
      return `width: calc(100% - 60px);`
    },
    filteredData() {
      let data = this.car_years;

      if (this.selected_year) {
        data = this.car_makes;
      }

      if (this.selected_make) {
        data = this.car_models
      }

      if (this.selected_model) {
        data = []
      }


      return this.query === ''
          ? data
          : data.filter((person) => {
            return person.name.toLowerCase().includes(this.query.toLowerCase())
          })
    }
  },
  methods: {
    getYearByQuery: function (value) {
      return this.car_years.find((year) => year.name.toLowerCase() === value.toLowerCase());
    },
    getMakeByQuery: function (value) {
      return this.car_makes.find((make) => make.name.toLowerCase() === value.toLowerCase());
    },
    getModelByQuery: function (value) {
      return this.car_models.find((model) => model.name.toLowerCase() === value.toLowerCase());
    },
    loadYears: async function () {
      const years = await VehicleDataService.get.years();
      this.car_years = years.data.years.reverse().map((year) => {
        return {
          name: year.toString(),
          id: year
        }
      });
    },
    loadMakes: async function () {
      const makes = await VehicleDataService.get.makes();
      this.car_makes = makes.data.makes
      // reorderarray desc
    },
    loadModels: async function () {
      const models = await VehicleDataService.get.models(this.selected_make.id);
      this.car_models = models.data.models
    },
    updateSelectedKey(value) {
      this.selected_key += value;

      if (this.selected_key < 0 || this.selected_key > this.filteredData.length - 1) {
        this.selected_key -= value;
      }

      this.$refs.optionsDropdown.scrollTo(0, this.selected_key > 3 ? ((this.selected_key * 41) - 160) : 0);
    },
    deleteButtonPressed() {
      if (this.query.length) {
        return;
      }
      if (this.selected_model) {
        this.selected_model = false;
        return;
      }
      if (this.selected_make) {
        this.selected_make = false;
        this.car_models = [];
        return;
      }
      this.selected_year = false;
    },
    selectKey(key) {
      // check if key is object
      this.query = typeof (key) !== 'object' ? this.filteredData[key].name : this.filteredData[this.selected_key].name;

    },
    updatePlaceholder() {
      if (!this.selected_year && !this.input_focused) {
        return this.current_placeholder = "Enter year/make/model";
      }
      if (!this.selected_year) {
        return this.current_placeholder = "Enter year"
      }
      if (!this.selected_make) {
        return this.current_placeholder = "Enter make"
      }
      return this.current_placeholder = "Enter model"
    },
    onInputFocus() {
      this.input_focused = true;
      this.updatePlaceholder()
    },
    inputFocusManually() {
      this.$refs.main_input.focus();
      setTimeout(() => {
        this.input_focused = true;
        this.query = '';
      }, 200);
    },
    onInputBlur() {
      setTimeout(() => {
        this.input_focused = false;
      }, 200);
      return this.updatePlaceholder()
    },
    async clearYear() {
      this.selected_model = false;
      // await timeout using promise
      await new Promise(resolve => setTimeout(resolve, 300));
      this.selected_make = false;
      await new Promise(resolve => setTimeout(resolve, 300));
      this.selected_year = false;

      // Focus on input
      this.$refs.main_input.focus();
      this.updatePlaceholder()
    },
    async clearMake() {
      this.selected_model = false;
      await new Promise(resolve => setTimeout(resolve, 300));
      this.selected_make = false;

      // Focus on input
      this.$refs.main_input.focus();

      this.updatePlaceholder()
    },
    async clearModel() {
      this.selected_model = false;

      // Focus on input
      this.$refs.main_input.focus();

      this.updatePlaceholder()
    },
    formatText(text) {
      // check if text is string
      if (typeof (text) !== 'string') {
        return text;
      }
      // If text length more than 12 put elipsis
      if (text.length > 12) {
        return text.substring(0, 12) + '...';
      }
      return text;
    },
    async spawnModal() {
      console.log("SPAWNING MODAL")
      this.modal_spawned = true;

      window.parent.postMessage({
        name: 'searchgenie.complete',
        payload: {
          selected_year: this.selected_year,
          selected_make: this.selected_make,
          selected_model: this.selected_model,

          top: document.querySelector('.main-input-box').getBoundingClientRect().top,
          left: document.querySelector('.main-input-box').getBoundingClientRect().left,
          height: document.querySelector('.main-input-box').offsetHeight,
          width: document.querySelector('.main-input-box').offsetWidth,

        }
      }, '*')


      // reset the input to its original state
      await new Promise(resolve => setTimeout(resolve, 3000));
      this.selected_model = false;
      this.modal_spawned = false;
      await this.clearYear()


    }
  },
  mounted() {
    // Get years
    this.car_years = this.loadYears();
    this.car_makes = this.loadMakes();

    // Get width of .main-input
    let main_box_width = document.querySelector('.main-input-box').offsetWidth;

    if (main_box_width < 640) {
      this.is_mobile = true;
    }

    let input_width = this.is_mobile ? 375 : 440;

    this.dimensions.year_width = input_width * 0.32;
    this.dimensions.make_width = input_width * 0.30;
    this.dimensions.model_width = input_width * 0.38;

    if (main_box_width < 600) {
      this.show_main_cta = false;
    }
  },
  watch: {
    query: function () {
      this.selected_key = 0
      if (!this.selected_year && this.getYearByQuery(this.query)) {
        this.selected_year = this.getYearByQuery(this.query);

        this.inputFocusManually()
        return this.query = ''
      }
      if (!this.selected_make && this.selected_year && this.getMakeByQuery(this.query)) {
        this.selected_make = this.getMakeByQuery(this.query);
        this.loadModels(this.selected_make.id)
        this.inputFocusManually()
        return this.query = ''
      }
      if (!this.selected_model && this.selected_make && this.getModelByQuery(this.query)) {
        this.selected_model = this.getModelByQuery(this.query);
        this.inputFocusManually()
        return this.query = ''
      }
    },
    selected_model: function () {
      if (!this.selected_model) {
        this.query = '';
        return;
      }
      if (this.is_mobile && this.selected_model) {
        this.spawnModal()
      }
      this.updatePlaceholder()
    },
    selected_year: function () {
      this.updatePlaceholder()
    },
    selected_make: function () {
      this.updatePlaceholder()
    },
  }
}
</script>