import httpClient from "@/client";

export default {
    get: {
        years: () => {
            return httpClient.get('api/estimatr/vehicles/years');
        },
        makes: () => {
            return httpClient.get('api/estimatr/vehicles/makes');
        },
        models: (make_id) => {
            return httpClient.get(`api/estimatr/vehicles/models?make_id=${make_id}`);
        }
    }
}