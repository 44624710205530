<template>
  <div class="">
    <search-input/>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchInput from "@/components/SearchInput";

export default {
  name: 'HomeView',
  components: {
    SearchInput,
  }
}
</script>
